/* RCF Print Styles */

/* Theming */
:root {
  --rcfPrint-font-family: "Open Sans", Arial, sans-serif;
  --rcfPrint-font-size: 16px;
  --rcfPrint-line-height: 1.5;
  --border-color: #bbb;
}
.theme_1 {
  --rcfPrint-font-family: "Open Sans", Arial, sans-serif;
  --rcfPrint-font-size: 16px;
  --rcfPrint-line-height: 1.5;
}
.theme_2 {
  --rcfPrint-font-family: "Castledown", Arial, sans-serif;
  --rcfPrint-font-size: 18px;
  --rcfPrint-line-height: 1.5;
}

/* Reset styles / browser overrides */
.rcfPrint-activityContainer * {
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
   print-color-adjust: exact !important;
}
.rcfPrint-activityContainer p {
  font-size: var(--rcfPrint-font-family);
  line-height: var(--rcfPrint-line-height);
}
.rcfPrint-activityContainer li + li {
  margin-top: 1.25em;
}
.rcfPrint-answerKey li + li {
  margin-top: .25em;
}
#rcf-print-contents > ol > li::marker,
#print-contents > li::marker {
  font-size: 1.25em;
}
#rcf-print-contents > ol > li + li,
#print-contents > li + li {
  margin-top: 1.25em;
}
#rcf-print-contents > ol > li + li:last-of-type,
#print-contents > li + li:last-of-type {
  margin-bottom: .5em;
}

/* Activity container */
.rcfPrint-activityContainer,
.rcfPrint-applyThemeFont {
  font-family: var(--rcfPrint-font-family);
  font-size: var(--rcfPrint-font-size);
  line-height: var(--rcfPrint-line-height);
}
.rcfPrint-activityContainer,
.rcfPrint-activityContainer * {
  box-sizing: border-box;
}

/* Rubric */
.rcfPrint-rubric {
  position: relative;
  font-size: 1.25rem;
}

/* Rubric & prompt audio icon */
.rcfPrint-rubric .rcfPrint-audioIcon,
.rcfPrint-prompt .rcfPrint-audioIcon {
  position: absolute;
}
.rcfPrint-rubric .rcfPrint-audioIcon:only-child,
.rcfPrint-prompt .rcfPrint-audioIcon:only-child {
  position: static;
  margin-right: .25rem;
  vertical-align: middle;
}
.rcfPrint-rubric .rcfPrint-audioIcon svg,
.rcfPrint-prompt .rcfPrint-audioIcon svg {
  position: relative;
  top: 3px;
}
.rcfPrint-rubric .rcfPrint-audioIcon ~ p,
.rcfPrint-prompt .rcfPrint-audioIcon ~ p {
  padding-left: calc(29px + .5rem);
}

/* Spacing between blocks */
.rcfPrint-main > * + * {
  margin-top: 1.5rem;
}

/* Plain lists */
.rcfPrint-activity ul.plain li {
  list-style-type: none;
}

/* Single item lists */
.rcfPrint-activity ol.li1 {
  padding-left: 0;
}
.rcfPrint-activity ol.li1 > li,
.rcfPrint-answerKey ol.answer1 > li {
  list-style-type: none;
}

/* Table */
.rcfPrint-activity table {
  border-collapse: collapse;
  border-spacing: 0;
	margin-bottom: 0.875em;
}
.rcfPrint-activity table tr td,
.rcfPrint-activity table tr th {
	background: none;
	color: #000;
	border: 1px solid var(--border-color);
  padding: 0.7em 0.875em;
}
.rcfPrint-activity table tr th {
  font-weight: 700;
  padding: 0.4em 0.875em;
}
.rcfPrint-activity table tr th {
	background: #efefef;
}

/* Inline interaction number */
.rcfPrint-number {
  font-size: smaller;
  font-weight: 700;
}

/* Images */
.rcfPrint-main img {
	max-width: 25%;
  vertical-align: text-top;
}
.rcfPrint-presentation img,
.mm_presentation img {
	width: 80%;
  max-width: 650px;
  margin-left: 10%;
}
.rcfPrint-presentation .rcfPrint-prompt img,
.mm_presentation .rcfPrint-prompt img {
  display: inline;
}
.rcfPrint-prompt img {
	max-width: 35%;
}

/* image containers / captions */
.rcfPrint-main .imageContainer {
  position: relative;
	text-align: left;
	display: block;
	margin-bottom: 0.875em;
  display: inline-block;
}

.rcfPrint-main .imageContainer.imageCaption img {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.rcfPrint-main .imageContainer .caption {
  position: absolute;
	bottom: 0;
	background-color: #000; /* IE8 Fallback */
	background-color: rgba(0, 0, 0, 0.7);
	color: #FFF;
	padding: 0;
	width: 100%;
	font-size: 0.7em;
	line-height: 1.3em;
	display: block;
}

.rcfPrint-main .imageContainer .caption.corner {
  width: auto;
	padding-right: 0.2em;
	font-size: 1em;
	line-height: 1em;
	font-weight: bold;
	-webkit-border-top-right-radius: 15px;
	-moz-border-radius-topright: 15px;
	border-top-right-radius: 15px;
}

.rcfPrint-main .imageContainer .caption .innerCaption {
  padding: 0.3em 0.5em;
	display: inline-block;
	zoom: 1;
}

/* Phons */
.rcfPrint-activity .phons {
	font-weight: bold;
}

/* Generic activity */
/* Typein & Droppable */
.rcfPrint-typein:not(.rcfPrint-answer),
.rcfPrint-complexdroppable:not(.rcfPrint-answer) {
  white-space: nowrap;
}
.rcfPrint-typein .rcfPrint-item,
.rcfPrint-complexdroppable .rcfPrint-dragTarget {
  position: relative;
}
.rcfPrint-complexdroppable .rcfPrint-dragTarget.rcfPrint-hasImage,
.rcfPrint-complexdroppable .rcfPrint-dragTarget.rcfPrint-hasImageAudio {
  padding: 0 1em;
}
.rcfPrint-typein:not(.rcfPrint-answer) .rcfPrint-item:before,
.rcfPrint-complexdroppable .rcfPrint-dragTarget:before {
  position: absolute;
  top: 2px;
  left: 0;
  content: "";
  width: 100%;
  height: 1em;
  border-bottom: 2px solid var(--border-color);
}
.rcfPrint-complexdroppable .rcfPrint-dragTarget.rcfPrint-hasImage:before,
.rcfPrint-complexdroppable .rcfPrint-dragTarget.rcfPrint-hasImageAudio:before {
  top: 3px;
}
.rcfPrint-typein .rcfPrint-item input,
.rcfPrint-complexdroppable .rcfPrint-dragTarget input {
  position: relative;
  border: none;
  background: none;
  font-size: 1rem;
  text-align: center;
  max-width: calc(100% - 45px);
}
.rcfPrint-typein.rcfPrint-example .rcfPrint-item input,
.rcfPrint-complexdroppable.rcfPrint-example .rcfPrint-dragTarget input {
  color: #949698;
  padding: 0 1rem;
  font-style: italic;
}

/* Droppable Wordbox */
.rcfPrint-wordBox {
  background-color: #ccc;
}
.rcfPrint-wordBox ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  max-width: 100%;
  width: fit-content;
  list-style: none;
  padding: 1rem 2rem;
  margin: auto;
}
.rcfPrint-wordBox ul li {
  padding: 0;
  margin: 0;
}
.rcfPrint-wordBox li.rcfPrint-hasImage,
.rcfPrint-wordBox li.rcfPrint-hasImageAudio {
  max-width: calc(25% - 1.5em); /* 25% - flex gap */
  text-align: center;
}
.rcfPrint-wordBox li img {
  max-width: 100%;
}
.rcfPrint-wordBox + * {
  margin-top: 1rem;
}

/* Dropdown */
.rcfPrint-dropdown .rcfPrint-separator {
  padding: 0 0.3rem;
}
.rcfPrint-dropdown .rcfPrint-separator,
.rcfPrint-dropdown .rcfPrint-text {
  font-weight: 700;
}
.rcfPrint-dropdown.rcfPrint-example {
  margin-left: 0.15rem;
}
.rcfPrint-dropdown.rcfPrint-example .rcfPrint-separator,
.rcfPrint-dropdown.rcfPrint-example .rcfPrint-text {
  color: #949698;
}
.rcfPrint-dropdown.rcfPrint-example .rcfPrint-correct .rcfPrint-text {
  outline: 2px solid var(--border-color);
  outline-offset: 1px;
  border-radius: 8px;
}
.rcfPrint-dropdown .rcfPrint-correct ~ .rcfPrint-correct .rcfPrint-text {
  border: none;
}

/* Radio & Checkbox */
.rcfPrint-radio ol, .rcfPrint-checkbox ol {
  padding: 0 0.5rem;
  margin: 0;
}
.rcfPrint-radio .rcfPrint-item, .rcfPrint-checkbox .rcfPrint-item {
  list-style-type: none;
}
.rcfPrint-radio .rcfPrint-itemNumber, .rcfPrint-checkbox .rcfPrint-itemNumber {
  padding: 0 0.5rem 0 0;
  font-weight: bold;
}
.rcfPrint-radio .rcfPrint-separator, .rcfPrint-checkbox .rcfPrint-separator {
  padding: 0 0.5rem;
}
.rcfPrint-radio .rcfPrint-correct .rcfPrint-text, .rcfPrint-checkbox .rcfPrint-correct .rcfPrint-text {
  position: relative;
}
.rcfPrint-radio .rcfPrint-correct .rcfPrint-text:before, .rcfPrint-checkbox .rcfPrint-correct .rcfPrint-text:before {
  position: absolute;
  content: "";
  border: 2px solid black;
  border-radius: 8px;
  top: -3px;
  left: -6px;
  width: calc(100% + 8px);
  height: calc(100% + 2px);
}
.rcfPrint-radio.rcfPrint-hasImages ol, .rcfPrint-checkbox.rcfPrint-hasImages ol {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.rcfPrint-radio.rcfPrint-hasImages li + li, .rcfPrint-checkbox.rcfPrint-hasImages li + li {
  margin-top: 0;
}
.rcfPrint-radio.rcfPrint-hasImages ol img, .rcfPrint-checkbox.rcfPrint-hasImages ol img {
  max-width: 75%;
}

/* Ordering */
.rcfPrint-ordering:not(.rcfPrint-answer) {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 0 2rem;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-item,
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-suffix {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-item + .rcfPrint-item,
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-item +  .rcfPrint-suffix {
  margin-top: .25rem;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-text,
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-image,
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-imageAudio {
  border: 1px solid black;
  padding: .5rem;
  margin-right: 1rem;
  width: 100%;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-image,
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-imageAudio {
  max-width: 50%;
  line-height: 0;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-image img,
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-imageAudio img {
  max-width: 100%;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-wol {
  padding-top: .5rem;
  width: 3rem;
  text-align: center;
  position: relative;
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-wol:before {
  position: absolute;
  padding-top: .5rem;
  top: 5px;
  left: 0;
  content: "";
  width: 100%;
  height: 1rem;
  border-bottom: 2px solid var(--border-color);
}
.rcfPrint-ordering:not(.rcfPrint-answer) .rcfPrint-suffix .rcfPrint-text + span {
  width: 3rem;
}

/* Selectable text */
.rcfPrint-interactivetextblock .rcfPrint-example,
.rcfPrint-interactivetextblock .rcfPrint-correct {
  position: relative;
  display: inline-block;
  max-width: 95%;
}
.rcfPrint-interactivetextblock.rcfPrint-type_selectableWords  .rcfPrint-example,
.rcfPrint-interactivetextblock.rcfPrint-type_selectableWords .rcfPrint-correct {
  margin: 0 .5rem;
}
.rcfPrint-interactivetextblock .rcfPrint-example:before,
.rcfPrint-interactivetextblock .rcfPrint-correct:before {
  position: absolute;
  content: "";
  border: 2px solid black;
  border-radius: 8px;
  top: -3px;
  left: -6px;
  width: calc(100% + 8px);
  height: calc(100% + 2px);
}
.rcfPrint-interactivetextblock .rcfPrint-example {
  color: #949698;
  font-style: italic;
}
.rcfPrint-interactivetextblock .rcfPrint-example:before {
  border-color: #949698;
}
.rcfPrint-interactivetextblock.rcfPrint-answer.rcfPrint-type_selectable .rcfPrint-selectable + .rcfPrint-selectable {
  margin-left: .75rem;
}

/* Matching */
.rcfPrint-matching:not(.rcfPrint-answer) {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 625px;
  width: 100%;
  padding: 0.5rem 0;
}
.rcfPrint-matching:not(.rcfPrint-answer) .rcfPrint-matchItems,
.rcfPrint-matching:not(.rcfPrint-answer) .rcfPrint-matchTargets {
  max-width: 30%;
  list-style-type: none;
  padding-inline-start: 0;
}
.rcfPrint-matching:not(.rcfPrint-answer) .rcfPrint-matchItem,
.rcfPrint-matching:not(.rcfPrint-answer) .rcfPrint-matchTarget {
  padding: 1rem;
  border: 1px solid var(--border-color);
}
.rcfPrint-matching:not(.rcfPrint-answer) .matchElementContainer {
  display: flex;
  flex-direction: row;
}
.rcfPrint-matching:not(.rcfPrint-answer) .matchElementContainer .matchElementIndex {
  min-width: 16px;
}
.rcfPrint-matching:not(.rcfPrint-answer) .matchElementContainer img {
  max-width: 100%;
}
.rcfPrint-matching.rcfPrint-answer {
  padding-inline-start: 0;
}
.rcfPrint-matching.rcfPrint-answer > span + span {
  margin-left: 1em;
}
.rcfPrint-matching.rcfPrint-answer > li {
  list-style-type: none;
}

/* Writing */
.rcfPrint-writing:not(.rcfPrint-answer):before {
  /* RCF-11182 – fix for list marker not aligning to the writing element */
  content: " ";
  display: block;
  width: 0;
  height: 0;
}
.rcfPrint-writing .rcfPrint-wol {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  border-bottom: 1px solid #000;
}

/* complex categorise checkbox table */

.rcfPrint-complex-categorise .rcfPrint-checkbox-table {
  width: 100%;
}

.rcfPrint-complex-categorise .rcfPrint-checkbox-table th,
.rcfPrint-complex-categorise .rcfPrint-checkbox-table td.rcf-category-item-choice {
  text-align: center;
}

.rcfPrint-complex-categorise .rcfPrint-checkbox-table td.rcf-category-item-name {
  text-align: left;
}

.rcfPrint-complex-categorise .rcfPrint-checkbox-table td.rcf-category-item-name.example {
  color: #949698;
  padding: 0 1rem;
  font-style: italic;
}

/* maths interactions */
.rcfPrint-activityContainer .mathsInteraction {
  font-size: larger;
  display: flex;
  padding: 0.5em;
  background: white;
}

.rcfPrint-activityContainer .rcfMathsTable {
  display: table;
  border-collapse: collapse;
  background: #fff;
  color: #333;
  border-collapse: collapse;
  margin-bottom: 0;
}

.rcfPrint-activityContainer .rcfMathsTable .cell {
  border: 1px solid #eee;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  height: 20px;
  min-height: 20px;
}

.rcfPrint-activityContainer .rcfMathsTable td.cell.symbol {
  vertical-align: middle;
  font-size: 1.2em;
}
.rcfPrint-activityContainer .rcfMathsTable .cell.answerSymbol {
	color: transparent;
}

.rcfPrint-activityContainer .rcfMathsTable .cell:not(:last-of-type) {
  border-right: none;
}

.rcfPrint-activityContainer .rcfMathsTable .cell.spacer,
.rcfPrint-activityContainer .rcfMathsTable .cell.decimal-point{
  border-left: none;
}

.rcfPrint-activityContainer .rcfMathsTable.hasDecimals .cell.spacer,
.rcfPrint-activityContainer .rcfMathsTable.hasDecimals .cell.decimal-point {
  max-width: 5px;
  min-width: 5px;
}

.rcfPrint-activityContainer .rcfMathsTable:not(.hasDecimals) .cell.spacer {
  display: none;
}

.rcfPrint-activityContainer .rcfMathsTable:not(.hasDecimals) .answerRow td {
  padding: 0.2em;
}

.rcfPrint-activityContainer .rcfMathsTable tr.workingRow td.cell {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.rcfPrint-activityContainer .mathsInteraction:not(.division) .rcfMathsTable tr.workingRow.firstWorkingRow td,
.rcfPrint-activityContainer .mathsInteraction:not(.division) .rcfMathsTable tr.answerRow td {
  border-top: 2px solid #949698;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.rcfPrint-activityContainer .rcfMathsTable tr.workingRow:not(.firstWorkingRow) td {
  border-top: 1px solid #fff;
}

.rcfPrint-activityContainer .rcfMathsTable tr.workingRow.lastWorkingRow td {
  padding-bottom: 0.2em;
}

.rcfPrint-activityContainer .rcfMathsTable .decimal-point {
  max-width: 10px;
  padding: 0;
  min-width: 10px;
}

.rcfPrint-activityContainer .rcfMathsTable input.answer-input,
.rcfPrint-activityContainer .rcfMathsTable .workingRow input.working-answer-input {
  text-align: center;
  font-size: inherit;
  border-radius: 5px;
  min-height: 1.5em;
  min-width: 1.2em;
  background: transparent;
  border: none;
  padding: 0;
}

.rcfPrint-activityContainer .rcfMathsTable input.answer-input.decimal-point {
  background-color: transparent;
  border: none;
  max-width: 5px;
  min-width: 5px;
}

.rcfPrint-activityContainer .rcfMathsTable tr td.cell.workingCell {
  padding: 0;
}

.rcfPrint-activityContainer .rcfMathsTable .workingRow .cell .working-answer-input.hidden {
  display: none;
}

/* styles for division */
/* english layout styles for divisor / dividend */
.rcfPrint-activityContainer .rcfPrint-mathsdivision:not(.example) .cell.divisor.symbol,
.rcfPrint-activityContainer .rcfPrint-mathsdivision:not(.example) .workingRow .cell.symbol {
  visibility: hidden;
}

/* overrides for division */
.rcfPrint-activityContainer .rcfPrint-mathsdivision .rcfMathsTable tr.workingRow.firstWorkingRow td,
.rcfPrint-activityContainer .rcfPrint-mathsdivision .rcfMathsTable tr.answerRow td {
  border-top: inherit;
}

.rcfPrint-activityContainer .division.layout-english table.rcfMathsTable tr.answerRow td {
  border-top: 1px solid #eee;
}

.rcfPrint-activityContainer .division.layout-english table.rcfMathsTable tr.answerRow td:nth-of-type(1) {
  border-right: none;
  border-bottom: none;
}

.rcfPrint-activityContainer .division.layout-english table.rcfMathsTable tr.answerRow td:nth-of-type(2) {
  border-left: none;
}

.rcfPrint-activityContainer .division.layout-english table.rcfMathsTable tr.sumRow td.cell {
  border-top: 2px solid black;
}

.rcfPrint-activityContainer .division.layout-english table.rcfMathsTable tr.sumRow td.cell:not(:last-child) {
  border-right: none;
}

.rcfPrint-activityContainer .division.layout-english table.rcfMathsTable tr.sumRow td.divisor.cell {
  border-right: 2px solid black;
  border-top: 1px solid transparent;
  min-width: 25px;
  letter-spacing: 0.2em;
  padding: 0.2em;
}


/* general division styles for working-out */
.rcfPrint-activityContainer .division .marks-container  {
  top: -100%;
}

/* the 'underlined' total cells in the working area */
.rcfPrint-activityContainer .division.example table.rcfMathsTable tr.productRow td.hasInput,
.rcfPrint-activityContainer .division.example table.rcfMathsTable tr.productRow td.productCell,
.rcfPrint-activityContainer .division.example table.rcfMathsTable tr.productRow td.productCell + td.spacer:has(+ td.productCell),
.rcfPrint-activityContainer .division.example table.rcfMathsTable tr.productRow td.productCell + td.spacer:has(+ td.hasInput),
.rcfPrint-activityContainer .division.example table.rcfMathsTable tr.productRow td.hasInput + td.spacer:has(+ td.hasInput) {
  border-bottom: 2px solid black;
}

/* remainders */
.rcfPrint-activityContainer .division table.rcfMathsTable tr.workingRow.remainderRow td {
  padding-top: 5px;
}

/* output a 'R' in the cell adjacent to a marked remainder cell - cheating? maybe :) */
.rcfPrint-activityContainer .division[data-rcfDecimalPlaces="0"][data-rcfMarkRemainder="y"] table.rcfMathsTable tr.sumRemainderRow td.cell:not(.hasInput):has(+ td.cell.spacer + td.cell.hasInput):after {
  content: 'R';
}

/* hide working rows (excluding sumRemainderRow) if showWorking=n and markRemainder=y in the xml */
.rcfPrint-activityContainer .division.onlyShowRemainderWorking table.rcfMathsTable tr.workingRow:not(.sumRemainderRow) {
  display: none;
}

/*fix inconsistent cell sizes across all maths*/
.rcfPrint-activityContainer .mathsInteraction:not(.division) .rcfMathsTable tr.answerRow td:first-of-type, .mathsInteraction:not(.division) .rcfMathsTable tr.workingRow.firstWorkingRow td:first-of-type {
    min-width: 1.2em;
}
.rcfPrint-activityContainer .mathsInteraction:not(.division) .rcfMathsTable tr.answerRow td:not(.decimal-point):not(.spacer):not(:first-of-type), .mathsInteraction:not(.division) .rcfMathsTable tr.workingRow.firstWorkingRow td:not(.decimal-point):not(.spacer):not(:first-of-type),
.rcfPrint-activityContainer .division table.rcfMathsTable td:last-of-type  {
    padding: .2em;
}

/* DIVISION - european layout */
.rcfPrint-activityContainer .mathsInteraction.division.layout-european .dev-european-layout {
	display: flex;
	align-items: flex-start;
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european .dev-european-layout .rcfMathsTable.rcfDivisionEuropeanAnswerTable {
	margin-left: 1em;
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european .dev-european-layout .rcfMathsTable tr:nth-child(2) td {
	border-top: none;
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european .dev-european-layout .rcfMathsTable tr:nth-child(2) td {
	border-top: none;
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european .dev-european-layout .rcfMathsTable.rcfDivisionEuropeanAnswerTable tr:nth-child(2) td {
	border-top: 2px solid #000;
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european .dev-european-layout .rcfMathsTable.rcfDivisionEuropeanAnswerTable tr td:first-child {
	border-left: 2px solid #000;
	padding-left: 1em;
	text-align: left;
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european[data-rcfShowWorking="n"] .dev-european-layout .rcfMathsTable tr.sumRow td:not(.spacer) {
	min-width: 1.25em
}

.rcfPrint-activityContainer .mathsInteraction.division.layout-european[data-rcfShowWorking="n"] .dev-european-layout .rcfMathsTable tr.sumRow td:first-child {
	display: none;
}

/* Free drawing */
.rcfPrint-freedrawing .rcfPrint-interactionContainer {
  text-align: center;
}

.rcfPrint-freedrawing .rcfPrint-interactionContainer .rcfPrint-image,
.rcfPrint-freedrawing .rcfPrint-interactionContainer .rcfPrint-canvasContainer {
  width: 80%;
  max-width: 650px;
}

.rcfPrint-freedrawing .rcfPrint-interactionContainer .rcfPrint-pseudoImage {
  padding-top: 56.25%
}

.rcfPrint-freedrawing .rcfPrint-supplementaryStudentsTextAnswer .rcfPrint-wol {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  border-bottom: 1px solid #000;
}

/* Free drawing answer key */
.rcfPrint-freedrawing.rcfPrint-answer .rcfPrint-answerModel .rcfPrint-answerModelImage {
  width: 80%;
  max-width: 650px;
}

/* WOL from WP content */
.rcfPrint-activityContainer .mm_wol {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  min-width: 3rem;
  border-bottom: 1px solid #000;
}

/* Score area */
.rcfPrint-score-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.rcfPrint-score {
  min-width: 120px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  border: 3px solid #444;
}
.rcfPrint-score-label {
  border-right: 3px solid #444;
  flex: 1;
}
.rcfPrint-score-value {
  flex: 1;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #e1e1e1;
  color: #000;
  font-weight: 700;
}

/* Used to hide duplicated content */
.rcfPrint-hidden {
  display: none;
}

/* Functional show/hide classes */
.rcfPrint-activity,
.rcfPrint-answerKey,
.rcfPrint-staticAudioTranscriptContainer {
  display: none;
}
.show_printQuestions .rcfPrint-activity,
.show_printAnswerKeys .rcfPrint-answerKey,
.show_printAudioScripts .rcfPrint-staticAudioTranscriptContainer,
.show_printListPreview .rcfPrint-activity {
  display: block;
}
.show_listPrintPreview .rcfPrint-writing .rcfPrint-wol {
  display: none;
}
.show_duplicatedPrintElements .rcfPrint-hidden {
  display: block;
}

/* Print page break rules */
.rcfPrint-score-area,
.rcfPrint-writing,
.rcfPrint-matching,
.rcfPrint-mathsaddition,
.rcfPrint-mathssubtraction,
.rcfPrint-mathsmultiplication,
.rcfPrint-mathsdivision {
  page-break-before: avoid;  /* fallback */
  break-before: avoid;
}

.rcfPrint-rubric,
.rcfPrint-wordBox,
.rcfPrint-prompt,
.rcfPrint-answerModel p:first-child,
.rcfPrint-markingGuidance p:first-child {
  page-break-after: avoid;  /* fallback */
  break-after: avoid;
}

.rcfPrint-rubric,
.rcfPrint-wordBox,
.rcfPrint-writing,
.rcfPrint-score-area,
.rcfPrint-activity .mm_interactive li,
.rcfPrint-answerKey li:not(:only-of-type),
.rcfPrint-answerKey li:has(.rcfMathsTable),
.rcfPrint-mathsaddition,
.rcfPrint-mathssubtraction,
.rcfPrint-mathsmultiplication,
.rcfPrint-mathsdivision,
.rcfPrint-matching {
  page-break-inside: avoid;  /* fallback */
  break-inside: avoid;
}

/* Answer Key */
.rcfPrint-answerKey ol.answer1 {
  padding-inline-start: 22px;
}
.rcfPrint-answerKey ol:not(.answer1) {
  padding-inline-start: 40px;
}
/* Debug/Viewer Styles */
#print-contents .rcfPrint-answerKey {
  margin-top: 2rem;
  border: 2px solid #000;
  padding: 8px;
}
#print-contents .rcfPrint-answerKey:before {
  content: "Answer Key";
  text-decoration: underline;
}
#print-contents .rcfPrint-staticAudioTranscriptContainer {
  margin-top: 2rem;
  border: 2px solid #000;
  padding: 8px;
}
#print-contents .rcfPrint-staticAudioTranscriptContainer:before {
  content: "Audio Transcript";
  text-decoration: underline;
}
#print-contents .rcfPrint-activity .rcfPrint-hidden,
#print-contents .rcfPrint-staticAudioTranscriptContainer.rcfPrint-hidden {
  display: none;
}
#print-contents.show_duplicatedPrintElements  .rcfPrint-activity .rcfPrint-hidden,
#print-contents.show_duplicatedPrintElements  .rcfPrint-staticAudioTranscriptContainer.rcfPrint-hidden {
  display: block;
}

/* Debug/Viewer Styles */
#print-contents .rcfPrint-activity,
#print-contents .rcfPrint-answerKey,
#print-contents .rcfPrint-staticAudioTranscriptContainer {
  display: block;
}

/* RCF-11575 colourText */
mark.colourText {
  background-color: inherit;
  color: #EB0104;
}
